import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../../components/Layout";
import CTASection from "../../components/CTASection";
import Author from "../../components/Author";
import { Helmet } from "react-helmet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <Layout {...props} cta title="Why Bedrock uses Prisma as its ORM" description="Prisma is the best ORM in Node.js, no contest. It's beautiful API, self-documenting TypeScript support, migrations and active maintainance make it my favorite choice!" image="https://bedrock.mxstbr.com/prisma-meta.png" />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div align="center" style={{
      marginTop: `2em`
    }}>
      <h1>{`Why Bedrock uses Prisma as its ORM`}</h1>
      <Author mdxType="Author" />
    </div>
    <p><a parentName="p" {...{
        "href": "https://prisma.io"
      }}><strong parentName="a">{`Prisma`}</strong></a>{` is the best Node.js ORM — no contest. I have used it for all of my projects over the past two years, and have had a fantastic experience with it. There are four main reasons I prefer it over the alternatives:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Beautiful API`}</strong>{`: The Prisma API is unparalleled, its one of the best APIs I have ever seen! Every name is exactly what it should be, every property is exactly where it should be, the whole thing just works. It was obviously very carefully designed and is intuitive all the way through.`}</li>
      <li parentName="ul"><strong parentName="li">{`Incredible TypeScript support`}</strong>{`: Even for the non-intuitive bits I only rarely have to look at the (excellent) documentation. The incredible TypeScript support means Prisma basically documents itself — I just start typing and it auto-completes me!`}</li>
      <li parentName="ul"><strong parentName="li">{`Migrations`}</strong>{`: Migrations are critical for production-ready systems, yet in Node.js ORMs they tend to be an afterthought. Not so with Prisma, it strikes the perfect balance between automating the tedious bits while giving you full control.`}</li>
      <li parentName="ul"><strong parentName="li">{`Active maintenance & community`}</strong>{`: Last but not least, Prisma is very actively maintained. Every two weeks they ship a minor release and invariably it just keeps getting better and better and better. On top of that, the community is very engaged, making it easy to get answers to any question you might have.`}</li>
    </ul>
    <p>{`All in all, I would highly recommend using Prisma for your next project, which is why Bedrock comes pre-configured with Prisma out of the box. However, it lets you switch to a different ORM if you want to, of course!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      